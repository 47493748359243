<template lang="pug">
.bg-blue-50.mb-24
  .container
    .max-w-4xl.mx-auto
      .prices-component
        .col-1
          h1 {{ $t('homePage.prices_title') }}
          listComponent(:listText="listText")
        .col-2
          CardComponent
            paragraphCard {{ $t('homePage.prices_description') }} {{ trialPeriod }}
            paragraphCard.price {{ trialPrice }}
            paragraphCard(v-html="$t('homePage.prices_description_2', [`${subscriptionPrice}`])")
            router-link(:to="{ name: 'builder/select-template' }")
              buttonCard {{ $t('create_my_cv_button') }}
</template>

<script>
import ParseCash from "@/composables/parse-cash";

import CardComponent from "../prices/components/card.vue";
import paragraphCard from "../prices/components/card-p.vue";
import buttonCard from "../prices/components/card-button.vue";
import listComponent from "../prices/components/list.vue";

const { parseCash } = ParseCash();

export default {
  name: "prices-component",
  components: {
    CardComponent,
    paragraphCard,
    buttonCard,
    listComponent,
  },
  computed: {
    listText() {
      return this.$tm('homePage.prices_list_options');
    },
    defaultProduct() {
      return this.$store.getters["ProductsStore/getproductDefault"];
    },
    trialPrice() {
      return parseCash(
        this.defaultProduct.price_trial,
        this.defaultProduct.product_currency,
        this.defaultProduct.product_sign
      );
    },
    subscriptionPrice() {
      return parseCash(
        this.defaultProduct.price_subscription,
        this.defaultProduct.product_currency,
        this.defaultProduct.product_sign
      );
    },
    trialPeriod() {
      const isInHours = this.defaultProduct.trial_period == 1;
      if (isInHours) return "24 horas";
      return this.defaultProduct.trial_period + " Días";
    },
  },
};
</script>

<style lang="scss" scoped>
.prices-component {
  @apply flex gap-12 py-24 flex-col items-center  md:flex-row;

  .col-1 {
    @apply flex flex-col justify-center flex-grow md:w-2/4 lg:w-3/5;
    h1 {
      @apply font-bold text-3xl pr-4 text-gray-700 text-center md:text-left;
    }
  }
  .col-2 {
    @apply flex-shrink-0 flex-grow  md:w-2/4 lg:w-2/5;
  }
}
</style>
